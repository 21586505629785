import { InitializeProps } from '@getpopsure/analytics';
import { ConsentObject, cookieType } from '@getpopsure/cookie-consent';
import { isProduction } from 'util/env';

interface TrackerOptions {
  snowplow?: {
    collector: string;
    appId: string;
  };
  googleTagManager?: {
    containerId: string;
    nonce?: string;
  };
}

/**
 * GTM environments are meant to be used only in development/staging environments.
 * Therefore, check the current env and don't add the params in production
 */
const googleTagManagerOptions: InitializeProps['googleTagManager'] = {
  containerId: process.env.NEXT_PUBLIC_GTM_ID ?? '',
  ...(isProduction
    ? {}
    : {
        auth: process.env.NEXT_PUBLIC_GTM_AUTH,
        preview: process.env.NEXT_PUBLIC_GTM_PREVIEW,
      }),
};

export const initializeTrackers = async (consent: ConsentObject) => {
  let trackerOptions: TrackerOptions = {
    snowplow: {
      collector: process.env.NEXT_PUBLIC_COLLECTOR_URL ?? '',
      appId: process.env.NEXT_PUBLIC_COLLECTOR_APP_ID ?? '',
    },
  };

  if (
    consent[cookieType.ad_storage] === 'granted' ||
    consent[cookieType.analytics_storage] === 'granted'
  ) {
    trackerOptions = {
      ...trackerOptions,
      googleTagManager: googleTagManagerOptions,
    };
  }

  const { initialize } = await import('@getpopsure/analytics');
  initialize(trackerOptions);
};
