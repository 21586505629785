import React, { useEffect, useState } from 'react';

/**
 * The DropdownSafeArea component draws an SVG element
 * between the button (here called anchor) and the submenu.
 *
 * This "safe area" prevents the submenu to close when the mouse
 * hovers the space between the button and the submenu.
 */
export const DropdownMenuSafeArea = ({
  anchor,
  submenu,
}: {
  anchor: HTMLElement | null;
  submenu: HTMLElement | null;
}) => {
  const [submenuChild, setSubmenu] = useState<Element | null>(null);

  useEffect(() => {
    if (submenu && submenu.children.length > 0) {
      setSubmenu(submenu.children[0]);
    }
  }, [submenu]);

  if (!anchor || !submenuChild) {
    return null;
  }

  const {
    height: anchorHeight,
    width: anchorWidth,
    y: anchorY,
    x: anchorX,
    top,
  } = anchor.getBoundingClientRect();
  const { width: submenuWidth, x: submenuX } =
    submenuChild.getBoundingClientRect();

  const VERTICAL_CORRECTION = 4;
  const HORIZONTAL_CORRECTION = 20;
  const startingPointY = anchorY + anchorHeight / 2 - VERTICAL_CORRECTION;

  return (
    <svg
      style={{
        position: 'fixed',
        width: submenuWidth,
        height: anchorHeight / 2 - VERTICAL_CORRECTION,
        pointerEvents: 'none',
        zIndex: 2,
        top: anchorY + anchorHeight / 2 + VERTICAL_CORRECTION,
        left: submenuX,
      }}
      id="svg-safe-area"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        pointerEvents="auto"
        fill="transparent"
        stroke="transparent"
        d={`M ${anchorX - submenuX} ${top}
            L ${HORIZONTAL_CORRECTION} ${startingPointY}
            L ${submenuWidth - HORIZONTAL_CORRECTION} ${startingPointY}
            L ${anchorX - submenuX + anchorWidth} ${top}
            Z
        `}
      />
    </svg>
  );
};
