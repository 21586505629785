/**
 * Initial Intercom configuration
 */
export const setupIntercomConfiguration = () => {
  // Do not run this code on the server
  if (typeof window === 'undefined') return;

  window.intercomSettings = {
    api_base: 'https://api-iam.eu.intercom.io',
    custom_launcher_selector: '#intercom_custom_button',
    hide_default_launcher: true,
  };
};
