// NEXT_PUBLIC_ENVIRONMENT can be set to either staging, production or development
const isCurrentEnv = (targetEnv) => {
  const environment = process.env.NEXT_PUBLIC_ENVIRONMENT;
  if (!environment) {
    throw new Error(
      'NEXT_PUBLIC_ENVIRONMENT is undefined. This will lead to unexpected behaviour of the website.'
    );
  }

  return environment === targetEnv;
};

const isProduction = isCurrentEnv('production');
const isStaging = isCurrentEnv('staging');
const isDevelopment = isCurrentEnv('development');
const isProductionOrStaging = isProduction || isStaging;
const isStagingOrDev = isStaging || isDevelopment;

module.exports = {
  isProduction,
  isStaging,
  isDevelopment,
  isProductionOrStaging,
  isStagingOrDev,
};
