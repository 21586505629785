import { useTranslation } from 'next-i18next';

export const HiringBadge = () => {
  const { t } = useTranslation();
  return (
    <div className="p-badge--default fw-bold tc-white bg-primary-500 ml8">
      {t('component.navigation.company.careers.hiring', 'Hiring')}
    </div>
  );
};
