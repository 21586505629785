const locales = require('./locales');
const { isDevelopment } = require('./util/env');

/** @type {import('next-i18next').UserConfig} */
module.exports = {
  i18n: {
    defaultLocale: 'en-de',
    locales,
    localeDetection: false,
  },
  saveMissing: true,
  serializeConfig: false,
  missingKeyHandler: (lng, ns, key) => {
    const message = `[Translation] Missing key: ${key} locale: ${lng} namespace: ${ns}`;
    if (isDevelopment) {
      throw new Error(message);
    }
  },
};
