import { createClient } from '@prismicio/client';
import { enableAutoPreviews } from '@prismicio/next';
import { PRISMIC_REPO_NAME } from 'constants/prismic';
import { NextApiRequest, PreviewData } from 'next';
import { env } from 'process';

export const createPrismicClient = (
  config: {
    previewData?: PreviewData;
    req?: NextApiRequest;
  } = {}
) => {
  const client = createClient(PRISMIC_REPO_NAME, {
    accessToken: env.PRISMIC_ACCESS_TOKEN,
  });
  enableAutoPreviews({
    client,
    previewData: config?.previewData, // passed from [uid].page.tsx
    req: config?.req, // passed from api/preview.page.ts
  });
  return client;
};
