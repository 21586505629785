import { captureException } from '@sentry/nextjs';

const FEATHER_BACKEND_URL = process.env.NEXT_PUBLIC_FEATHER_API_URL || '';

export const saveFinanceAdsTrackingIdToCookie = async (s_id: string) => {
  try {
    await fetch(`${FEATHER_BACKEND_URL}/finance_ads/save_sid`, {
      method: 'POST',
      credentials: 'include',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        financeAdsTrackingId: s_id,
      }),
    });
  } catch (error) {
    captureException(error);
  }
};
