import { Region } from '@getpopsure/public-models';
import { getQuerySegmentFromPath } from 'util/pathSegmentUtil';

export const getRegionLandingPageUrl = (
  newCountry: { languages: { id: string; label: string }[]; id: Region },
  asPath: string
): string => {
  return `/${newCountry.languages[0].id}-${
    newCountry.id
  }${getQuerySegmentFromPath(asPath)}`;
};
