import { app } from '@getpopsure/private-constants';
import { UserIcon } from '@popsure/dirty-swan';
import LinkTo from 'components/linkTo';
import { useTranslation } from 'next-i18next';
import React from 'react';

import styles from './style.module.scss';

export const LogInButton = () => {
  const { t } = useTranslation();
  return (
    <LinkTo
      className={`d-flex ai-center gap8 br4 ${styles.logInButton}`}
      href={`${app.base}/account/signin`} // TODO: Safer way to access web app routes
    >
      <UserIcon noMargin size={20} />
      {t('component.navigation.account.link', 'My account')}
    </LinkTo>
  );
};
