import { FilledLinkToWebField } from '@prismicio/types';
import { GetValues } from 'strapi/types/utils';
import { NavigationDocument } from 'types.generated';

export const getMenuFromStrapiNavigationData = (
  data: GetValues<'api::navigation.navigation'>
) =>
  (data.items ?? []).map((category) => ({
    title: category.categoryTitle,
    icon: category.icon?.data?.attributes.url,
    products: (category.items ?? []).map((item) => ({
      name: item.name,
      url: item.internalPath || item.externalUrl,
    })),
  }));

export const getMenuFromPrismicNavigationData = (doc: NavigationDocument) =>
  doc.data.slices.map((slice) => ({
    title: slice.primary.category_title,
    icon: slice.primary.icon.url,
    products: slice.items.map((item) => ({
      name: item.name,
      url:
        item.internal_path || (item.external_url as FilledLinkToWebField).url,
    })),
  }));

export const getFooterFromStrapiNavigationData = (
  data: GetValues<'api::navigation.navigation'>
) => {
  const footer = (data.items ?? [])[0]; // footer always has one nav "items"
  return {
    title: footer.categoryTitle,
    products: (footer.items ?? []).map((item) => ({
      id: item.name,
      name: item.name,
      url: item.internalPath || item.externalUrl,
    })),
  };
};

export const getFooterFromPrismicNavigationData = (doc: NavigationDocument) => {
  const { slices } = doc.data;
  return {
    title: slices[0]?.primary.category_title,
    products: slices[0]?.items.map((item) => ({
      id: item.name,
      name: item.name,
      url:
        item.internal_path || (item.external_url as FilledLinkToWebField).url,
    })),
  };
};
