import Link, { LinkProps } from 'next/link';
import { ReactNode } from 'react';
import isExternalUrl from 'util/isExternalUrl';

const LinkTo = ({
  href,
  children,
  linkProps,
  ...props
}: Omit<JSX.IntrinsicElements['a'], 'href' | 'children'> & {
  href: string;
  children: ReactNode;
  linkProps?: Omit<LinkProps, 'href'>;
}) => {
  if (isExternalUrl(href)) {
    return (
      <a href={href} {...props}>
        {children}
      </a>
    );
  }
  return (
    <Link
      href={href}
      {...linkProps}
      className={props.className ?? ''}
      target={props.target}
      id={props.id}
    >
      {children}
    </Link>
  );
};

export default LinkTo;
