import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import HiringBadge from 'components/navigationBar/components/HiringBadge';
import React from 'react';

import styles from './style.module.scss';

export const DropdownMenu = ({
  menu,
  isHiring,
}: {
  menu: NavigationLink[];
  isHiring?: boolean;
}) => (
  <ul className={`pb32 px40 br8 ${styles.dropdown}`}>
    {menu.map(({ name, url, decorator, icon }) => (
      <li key={name}>
        {decorator === 'SEPARATOR' && <hr className={styles.separator} />}
        <div className="d-flex ai-center gap8 mt24">
          <div>
            <LinkTo
              href={url}
              className={classNames(
                'd-flex ai-center gap16 br4',
                styles.productContainer
              )}
              aria-label={name}
            >
              {Boolean(icon) && icon}
              <p className={styles.productTitle}>{name}</p>
            </LinkTo>
          </div>
          {decorator === 'HIRING_BADGE' && isHiring && <HiringBadge />}
        </div>
      </li>
    ))}
  </ul>
);
