import classNames from 'classnames';
import LinkTo from 'components/linkTo';
import {
  DropdownMenu,
  DropdownMenuWithCategory,
  LanguageSwitcher,
  LogInButton,
} from 'components/navigationBar/components';
import { ENGLISH_LOCALE_IDENTIFIER } from 'constants/i18n';
import { advice, company, resourcesMain } from 'content/navigation/links';
import { useEscapeKey } from 'hooks/useEscapeKey';
import { useFocusWithin } from 'hooks/useFocusWithin';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import React, {
  forwardRef,
  ReactNode,
  Ref,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import { DropdownMenuSafeArea } from '../components/DropdownMenuSafeArea';
import styles from './style.module.scss';

const DesktopNavigationBar = forwardRef(
  (
    {
      menu,
      isHiring,
    }: {
      menu: NavigationProductCategory[];
      isHiring: boolean;
    },
    ref: Ref<HTMLDivElement>
  ) => {
    const router = useRouter();
    const { t } = useTranslation();
    const isLocaleEnDe = router.locale === ENGLISH_LOCALE_IDENTIFIER;

    const getLocalizedLinks = (links: NavigationLink[]) =>
      links.filter(
        ({ locales }) =>
          !(router.locale && locales && !locales.includes(router.locale))
      );

    return (
      <div ref={ref} className={styles.container}>
        <div className={styles.wrapper}>
          <div className={`d-flex ai-center jc-between ${styles.content}`}>
            <LinkTo
              className={`br4 ${styles.logoWrapper}`}
              href="/"
              aria-label={t('component.navigation.logo.aria')}
            >
              <div className={styles.logo} />
            </LinkTo>
            <div>
              <div className={styles.linkContainer}>
                <DropdownMenuContainer
                  title={t('component.navigation.product')}
                  hasCategories={menu.length > 1}
                >
                  {menu.length > 1 ? (
                    <DropdownMenuWithCategory menu={menu} />
                  ) : (
                    <DropdownMenu menu={menu[0].products} />
                  )}
                </DropdownMenuContainer>
                {isLocaleEnDe && (
                  <DropdownMenuContainer
                    title={t('component.navigation.advice')}
                  >
                    <DropdownMenu menu={advice(t)} />
                  </DropdownMenuContainer>
                )}
                <DropdownMenuContainer
                  title={t('component.navigation.company')}
                >
                  <DropdownMenu
                    menu={getLocalizedLinks(company(t))}
                    isHiring={isHiring}
                  />
                </DropdownMenuContainer>

                {getLocalizedLinks(resourcesMain(t)).map(({ name, url }) => (
                  <LinkTo
                    key={name}
                    className={classNames(styles.link, {
                      [styles.linkActive]: router.pathname === url,
                    })}
                    href={url}
                  >
                    <span className={classNames(styles.innerLink, 'br4')}>
                      {name}
                    </span>
                  </LinkTo>
                ))}
              </div>
            </div>
            <div
              className={`d-flex gap24 ai-center ${styles.rightButtonContainer}`}
            >
              <LanguageSwitcher />
              <LogInButton />
            </div>
          </div>
        </div>
      </div>
    );
  }
);

const DropdownMenuContainer = ({
  title,
  children,
  hasCategories = false,
}: {
  title: string;
  children: ReactNode;
  hasCategories?: boolean;
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const triggerButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  /**
   * Cleanup timer on unmount
   */
  useEffect(() => {
    return () => {
      if (timer.current) clearTimeout(timer.current);
    };
  }, []);

  /**
   * We are adding a tiny timeout when customers hover menu items
   * to avoid flickering when moving through items
   */
  const handleMouseEnter = () => {
    timer.current = setTimeout(() => {
      setIsOpen(true);
    }, 150);
  };
  const handleMouseLeave = () => {
    setIsOpen(false);
    if (timer.current) clearTimeout(timer.current);
  };

  const handleOnEscape = useCallback(() => {
    if (isOpen) {
      setIsOpen(false);
      triggerButtonRef.current?.focus();
    }
  }, [isOpen]);

  const handleOnFocusWithinChange = useCallback(
    (isFocusWithinMenu: boolean) => {
      if (!isOpen) {
        return;
      }

      if (!isFocusWithinMenu) {
        setIsOpen(false);
      }
    },
    [isOpen]
  );

  useEscapeKey(handleOnEscape);
  useFocusWithin(dropdownMenuRef?.current, handleOnFocusWithinChange);

  return (
    <div
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className={classNames(styles.productLinkContainer, {
        [styles.productLinkContainerWithCategories]: hasCategories,
      })}
    >
      <button
        type="button"
        aria-haspopup="true"
        aria-expanded={isOpen ? 'true' : 'false'}
        className={classNames(
          'd-inline-flex ai-center jc-center',
          styles.link,
          {
            [styles.linkActive]: isOpen,
          }
        )}
        onClick={() => setIsOpen(!isOpen)}
        ref={triggerButtonRef}
      >
        <span className={classNames(styles.innerLink, 'br4')}>{title}</span>
      </button>
      {isOpen && (
        <DropdownMenuSafeArea
          anchor={triggerButtonRef.current}
          submenu={dropdownMenuRef.current}
        />
      )}

      <div className={isOpen ? 'd-block' : 'd-none'} ref={dropdownMenuRef}>
        {children}
      </div>
    </div>
  );
};

export default DesktopNavigationBar;
