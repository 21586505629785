import { useEffect, useRef, useState } from 'react';

export const useNavOffset = () => {
  const [navOffset, setNavOffset] = useState(0);
  const desktopNavRef = useRef<HTMLDivElement>(null);
  const mobileNavRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    setNavOffset(
      desktopNavRef.current?.clientHeight ||
        mobileNavRef.current?.clientHeight ||
        0
    );
  }, []);

  return { navOffset, allRefs: { desktopNavRef, mobileNavRef } };
};
