import { website } from '@getpopsure/private-constants';
import { ENGLISH_LOCALE_IDENTIFIER } from 'constants/i18n';
import { nonProductLinks } from 'constants/internalLinks';
import { NextRouter } from 'next/router';
import { isValidSubdomain } from 'util/validateSubdomain';

/**
 * This function will return the canonical url for the route.
 * It'll remove:
 * 1) the locale segment /en-de/ and add the /de-de/, /en-es/, /es-es/, etc. if needed
 * 2) the subdomain segment /default or /business
 * @param router the NextJs router returned by useRoute()
 */
export const canonicalUrlForRouter = (
  router: Pick<NextRouter, 'locale' | 'asPath'>
): string => {
  const pathWithoutSubdomain = getPathWithoutSubdomain(router.asPath);

  const localeBase = getLocaleBase(router.locale ?? ENGLISH_LOCALE_IDENTIFIER);
  const pathWithLocale = getPathWithLocale(pathWithoutSubdomain, localeBase);

  const isRouteWithEnDeCanonical = [
    nonProductLinks.ABOUT_US.path,
    nonProductLinks.BECOME_A_PARTNER.path,
    nonProductLinks.CAREER.path,
  ].includes(pathWithoutSubdomain);

  const path = isRouteWithEnDeCanonical ? pathWithoutSubdomain : pathWithLocale;

  return website.base + path;
};

const getPathWithoutSubdomain = (path: string): string => {
  const pathWithoutQuery = path.split('?')[0];
  // remove the first element returned by split, which is an empty string
  const pathSegments = pathWithoutQuery.split('/').slice(1);

  // on server side, router.asPath is a rewritten internal path returned by middleware
  // remove [subdomain] segment if it exists
  const pathSegmentsWithoutSubdomain = isValidSubdomain(pathSegments[0])
    ? pathSegments.slice(1)
    : pathSegments;

  return `/${pathSegmentsWithoutSubdomain.join('/')}`;
};

const getLocaleBase = (locale: string) =>
  locale === ENGLISH_LOCALE_IDENTIFIER ? '' : `/${locale}`;

const getPathWithLocale = (path: string, localeBase: string) =>
  isHomepage(path) ? localeBase : localeBase + path;

const isHomepage = (path: string) => path === '/';
