import { mobileApp } from '@getpopsure/private-constants';
import classNames from 'classnames';
import Image from 'next/image';
import { useRouter } from 'next/router';
import downloadGooglePlay from 'public/static/footer/download-app-android.svg';
import downloadAppStore from 'public/static/footer/download-app-apple.svg';
import { getCountryFromLocaleIdentifier } from 'util/getCountryFromLocaleIdentifier';

import styles from './FooterMobileApp.module.scss';

const FooterMobileApp = () => {
  const { locale: currentLocale } = useRouter();
  const region = getCountryFromLocaleIdentifier(currentLocale);

  if (region !== 'de') {
    return null;
  }

  return (
    <div className={classNames(styles.container, 'd-block')}>
      {mobileApp?.iosAppURL && (
        <a
          className="mr8"
          href={mobileApp.iosAppURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            alt="Download on the App Store"
            src={downloadAppStore}
            width="120"
          />
        </a>
      )}

      {mobileApp?.androidAppURL && (
        <a
          href={mobileApp.androidAppURL}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Image
            alt="Get it on Google Play"
            src={downloadGooglePlay}
            width="135"
          />
        </a>
      )}
    </div>
  );
};

export default FooterMobileApp;
