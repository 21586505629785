import {
  ConsentObject,
  updateConsentInTagManager,
} from '@getpopsure/cookie-consent';

import { initializeTrackers } from './initializeTrackers';

export const setTrackersAfterConsent = async (consent: ConsentObject) => {
  await initializeTrackers(consent);
  updateConsentInTagManager(consent);
};
