module.exports = [
  'en-de',
  'de-de',
  'en-es',
  'es-es',
  'en-fr',
  'fr-fr',
  'en-nl',
  'en-it',
  'en-be',
  'en-se',
  'en-mt',
  'en-at',
  // 'en-fi',
  // 'en-cy',
  // 'en-gr',
  // 'en-ee',
  'en-pt',
  // 'en-lt',
  // 'en-hr',
  // 'en-lv',
  // 'en-mc',
  // 'en-eu',
];
