import { type Subdomain, SUBDOMAINS } from 'constants/subdomains';

export const isValidSubdomain = (subdomain: string): subdomain is Subdomain =>
  (SUBDOMAINS as readonly string[]).includes(subdomain);

export const validateSubdomain = (subdomain: unknown): Subdomain => {
  if (typeof subdomain !== 'string') throw Error('subdomain is not a string');
  if (!isValidSubdomain(subdomain)) throw Error('subdomain is not valid');
  return subdomain;
};
