import React, { Ref } from 'react';

import Desktop from './desktop';
import Mobile from './mobile';
import styles from './style.module.scss';

interface NavigationBarProps {
  allRefs?: {
    desktopNavRef: Ref<HTMLDivElement>;
    mobileNavRef: Ref<HTMLDivElement>;
  };
  menu: NavigationProductCategory[];
  isHiring: boolean;
}

const NavigationBar = ({ allRefs, menu, isHiring }: NavigationBarProps) => {
  return (
    <nav role="navigation">
      <div className={styles.desktop}>
        <Desktop ref={allRefs?.desktopNavRef} menu={menu} isHiring={isHiring} />
      </div>
      <div className={styles.mobile}>
        <Mobile ref={allRefs?.mobileNavRef} menu={menu} isHiring={isHiring} />
      </div>
    </nav>
  );
};

export default NavigationBar;
